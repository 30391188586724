import { createSlice } from '@reduxjs/toolkit';
// utils
import contactsApi from '../../api/contacts';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gotContactSuccess(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    },
    contactSaved(state, action) {
      state.loading = false;
      state.list.push(action.payload);
    },

    contactUpdated(state, action) {
      state.loading = false;
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) state.list[index] = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, gotContactSuccess, contactSaved, contactUpdated } = slice.actions;

// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch, getState) => {
    const {
      contacts: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await contactsApi.getAll();
      dispatch(gotContactSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function saveContact(data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await contactsApi.save(data);
      dispatch(contactSaved(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}

export function updateContact(id, data) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await contactsApi.update(id, data);
      dispatch(
        contactUpdated({
          id,
          ...data
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
