import { createSlice } from '@reduxjs/toolkit';
// utils
import incomesApi from '../../api/incomes';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  fetched: false,
  error: false,
  list: []
};

const slice = createSlice({
  name: 'incomes',
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    gotIncomes(state, action) {
      state.loading = false;
      state.fetched = true;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, hasError, gotIncomes } = slice.actions;

// ----------------------------------------------------------------------

export function getIncomes() {
  return async (dispatch, getState) => {
    const {
      incomes: { fetched, list }
    } = getState();

    if (fetched) return list;

    try {
      dispatch(startLoading());
      const response = await incomesApi.getAll();
      dispatch(gotIncomes(response.data));
      return response.data;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
}
