import axios from '../utils/axios';

const getAll = () => axios.get('/contacts');

const update = (id, data) => axios.put(`/contacts/${id}`, data);

const save = (data) => axios.post(`/contacts`, data);

export default {
  getAll,
  update,
  save
};
