// routes
import { PATH } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  posts: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH.dashboard, icon: ICONS.dashboard },
      { title: 'Propiedades', path: PATH.properties.list, icon: ICONS.analytics },
      { title: 'Ingresos', path: PATH.incomes.list, icon: ICONS.ecommerce },
      { title: 'Contactos', path: PATH.contacts.list, icon: ICONS.booking }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Administración',
    items: [
      { title: 'Ciudades', path: PATH.cities.list, icon: ICONS.kanban },
      { title: 'Colonias', path: PATH.suburbs.list, icon: ICONS.banking }
    ]
  }
];

export default sidebarConfig;
